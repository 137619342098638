import { useEffect } from "react";
import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  UserButton,
  useUser,
} from "@clerk/clerk-react";

// Replace with your instance settings
const clerkFrontendApi = process.env.REACT_APP_CLERK_FRONTEND_API;
const clerkSignInURL = process.env.REACT_APP_CLERK_SIGN_IN_URL;

function App() {
  console.log()
  return (
    <ClerkProvider frontendApi={clerkFrontendApi}>
      <SignedIn>
        <div className="top-bar">
          <Logo />              
          <div className="user-button">
            <UserButton className="user-button"/>
          </div>
        </div>
        <Greeting />
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </ClerkProvider>  
  );
}

function Logo() {
  return <img className="logo" src="https://cdn.shopify.com/s/files/1/1075/8124/files/logo_ee7379b4-0d4e-43f0-830c-1b3d13a9580d_180x.png?v=1599459567"></img>
  
}
function Greeting() {
  const { firstName } = useUser();
  return <>
          <div>Welcome {firstName}</div>
          <br/>
          <div>This site is used to manage your Trading Experts app membership.</div>
          <br/>
          <div>You're currently an <strong>Alpha</strong> Member</div>
          <br/>
          <div>Contact <strong>ben@tradingexperts.org</strong> for any issues</div>
        </>
}

function RedirectToSignIn() {
  useEffect(() => {
    window.location.href = clerkSignInURL;
  });
  return null;
}

export default App;